import React from 'react';
import { Col, Container, ResponsiveEmbed, Row, Table } from 'react-bootstrap';
import { graphql, Link } from 'gatsby';
import { Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { orderBy } from 'lodash';
import { videoFallback } from '@variables';
import { dayjs } from '@utils';
import '@scss/judging-page.scss';

const getProjects = (session) => {
  switch (session.type) {
    case `SENIOR_DESIGN`:
      return orderBy(session.seniorProjects, `judgingTime`)
        .map(project => ({ ...project, projectPage: `/seniors` }));
    case `RESEARCH_SYMPOSIUM`:
      return orderBy(session.researchProjects, `judgingTime`)
        .map(project => ({ ...project, projectPage: `/research` }));
    case `HIGH_SCHOOL`:
      return orderBy(session.highSchoolProjects, `judgingTime`)
        .map(project => ({ ...project, projectPage: `/high-school` }));
    default:
      return [];
  }
};

const JudgingPage = ({ data }) => {
  const { session } = data.postgres;

  return <Layout className="judging-page">
    <Navbar year={session.year} />
    <PageHeader
      title={session?.topic}
      subTextComponent={<p>
        {session?.type === `SENIOR_DESIGN` ? `Senior Design` : session?.type === `RESEARCH_SYMPOSIUM` ?
          `Research Symposium` : `High School` } {session?.year}
      </p>}
    />

    <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
      <Row className="justify-content-center mb-4">
        <Col lg={8}>
          <ResponsiveEmbed aspectRatio="16by9">
            <iframe
              title={`${session?.topic} - ${session?.type}`}
              src={videoFallback ? session?.backupVideoUrl : session?.videoUrl}
              className="embed-responsive-item"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </ResponsiveEmbed>
          <p className="mt-2">Judging will start at 10AM Eastern Time</p>
        </Col>
      </Row>
      <Row>
        {
          session?.exactTimes ?
            <Col>
              <h2 style={{ marginBottom: `1rem`, textAlign: `center` }}>Presentation Schedule</h2>
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Project</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {
                      session.type === `RESEARCH_SYMPOSIUM` &&
                        <tr>
                          <td>{dayjs(new Date(`2022-04-12 14:00:00+00`)).format(`hh:mm A`)}</td>
                          <td>Research Symposium Welcoming Remarks</td>
                        </tr>
                    }
                    {
                      getProjects(session).map(project =>
                        <tr key={project.id}>
                          <td>{project.judgingTime ? dayjs(project.judgingTime).format(`hh:mm A`) : `N/A`}</td>
                          <td><Link to={`${project.projectPage}/${project.id}`}>{project.title}</Link></td>
                        </tr>)
                    }
                  </>
                </tbody>
              </Table>
            </Col> :
            <Col>
              <h2>Order of Judging</h2>
              <ol className="project-order">
                {
                  getProjects(session).map(project =>
                    <li key={project.id}>
                      <Link to={`${project.projectPage}/${project.id}`}>{project.title}</Link>
                    </li>)
                }
              </ol>
            </Col>
        }
      </Row>
    </Container>

    <Sponsor />
    <Footer />

  </Layout>;
};

export const query = graphql`
query JudgingPageQuery($sessionId: Int!) {
  postgres {
    session: judgingSessionById(id: $sessionId) {
      id
      exactTimes
      backupVideoUrl
      topic
      type
      videoUrl
      year
      researchProjects: researchProjectsByJudgingSessionIdList {
        id
        title
        judgingTime
      }
      seniorProjects: seniorDesignProjectsByJudgingSessionIdList {
        id
        title
        judgingTime
      }
      highSchoolProjects: highSchoolProjectsByJudgingSessionIdList {
        id
        title
        judgingTime
      }
    }
  }
}
`;

export default JudgingPage;
